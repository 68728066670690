import React from 'react'
import "./loader.scss"

const Loader = () => {
  return (
    <div>
        <div className="lds-ring">
          <div>
          </div>
        <div>
          </div>
        <div>
          </div>
        <div>
          </div>
        </div>
    </div>
  )
}

export default Loader